import { interceptorFunction } from "../interceptors";
import { getToken } from "../../utils/general";
const interceptor = interceptorFunction();

export interface IForgotPassword {
  email: string;
}

export interface IResetPassword {
  code: string;
  password: string;
  id: string;
  email: string;
}

export interface ILogin {
  email: string;
  password: string;
}

export interface IAddOem {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  phone_iso: string;
  company_name: string;
  address: string;
  city: string;
  zip_code: string;
  country_id: number;
  state_id: number;
}

export const forgotPassword = (data: IForgotPassword) =>
  interceptor.post(`/account/forgot-password`, data);

export const resetPassword = (data: IResetPassword) =>
  interceptor.post(`/account/reset-credentials`, data);

export const login = (data: ILogin) => interceptor.post(`/account/login`, data);

export const getProfile = () =>
  interceptor.get(`/account/profile`, {
    headers: {
      authorization: `Bearer ${getToken()}`,
    },
  });

export const updateProfile = (data: any) =>
  interceptor.patch(`/account/profile`, data, {
    headers: {
      authorization: `Bearer ${getToken()}`,
    },
  });

export const changePassword = (data: any) =>
  interceptor.post(`/account/change-password`, data, {
    headers: {
      authorization: `Bearer ${getToken()}`,
    },
  });
