import IntuIonsIcon from "../../Icons/IntuIonsIcon";

const LoginWelcomeContentTwo: React.FC = (): JSX.Element => (
  <div className="w-[full] h-[80%] flex flex-col justify-between items-center">
    <div className="w-[90%] py-10">
      <IntuIonsIcon />
    </div>
    <div className="w-[90%] flex flex-col gap-3">
      <span className="font-semibold text-others-white text-h4">
        Cloud-Powered EV Telemetry Solutions
      </span>

      <span className="font-normal text-others-white text-subtitle2">
        Team up with Intutions for taking your EV telemetry data to the cloud enabling you to
        perform fault diagnostics & EV health checks
      </span>
    </div>
  </div>
);

export default LoginWelcomeContentTwo;
