import IntuIonsIcon from "../../Icons/IntuIonsIcon";

const LoginWelcomeContentOne: React.FC = (): JSX.Element => (
  <div className="w-[full] h-[80%] flex flex-col justify-between items-center">
    <div className="w-[90%] py-10">
      <IntuIonsIcon />
    </div>
    <div className="w-[90%] flex flex-col gap-3">
      <span className="font-semibold text-others-white text-h4">
        Accelerating into Sustainable Transportation
      </span>

      <span className="font-normal text-others-white text-subtitle2">
        A Groundbreaking Software Platform to help EV, BMS and EV Charger OEMs accelerate hardware
        innovations
      </span>
    </div>
  </div>
);

export default LoginWelcomeContentOne;
