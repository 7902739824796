import Cookies from "js-cookie";
import moment from "moment";

export const readCookie = () => {
  const token = Cookies.get("token");
  const refreshToken = Cookies.get("refreshToken");
  const sessionTime: any = Cookies.get("sessionTime");

  return [token || "", refreshToken || "", sessionTime || ""];
};

export const setCookie = (
  token: string,
  refreshToken: string,
  sessionTime?: string | number | any,
) => {
  Cookies.set("token", token);
  Cookies.set("refreshToken", refreshToken);
  if (sessionTime) {
    Cookies.set("sessionTime", sessionTime);
  }
};

export const clearStorage = () => {
  Cookies.remove("token");
  Cookies.remove("refreshToken");
  Cookies.remove("sessionTime");
};

export const getToken = () => {
  const token = Cookies.get("token");
  return token ?? "";
};

export const jsonToURI = (json: object) => encodeURIComponent(JSON.stringify(json));

export const uriToJSON = (uri: string) => JSON.parse(decodeURIComponent(uri));

export const getCountry = (countries: any, id: number): Record<string, any> =>
  countries.find((country: any) => country.id === id);

export const getState = (countries: any, id: number): Record<string, any> => {
  let stateObj = {};
  /* eslint array-callback-return: "off" */
  countries.find((country: any) => {
    /* eslint array-callback-return: "off" */
    country.states.find((state: any) => {
      if (state.gid === id) {
        stateObj = state;
      }
    });
  });
  return stateObj;
};

export const dateFormatter = (date: string) => moment(date).format("DD/MM/YYYY hh:mm A");
